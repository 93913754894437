import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SearchForm from '../components/search'
import RenderBlock from '../utils/render-block'
import he from 'he'
import Seo from '../components/seo'
import JobCard from '../components/cards/job'
import Fade from 'react-reveal/Fade'

class JobSearchPage extends Component {

  state = {
    display: this.props.data.properties || []
  }

  render() {
    
    let page = this.props.data.wpPage
    const { display } = this.state
    const { properties } = this.props.data

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page'))  }
        <SearchForm list={properties || []} set={list => this.setState({ display: list })} />

        <section className="jobs">
          <div className="jobs__inner">
            <div className="jobs__content">
            { display && display.edges.map((el, i) => {
              return (
                <Fade bottom distance='30px'>
                  <div className='job__item' key={i}>
                    <JobCard post_type='job' {...el.node} />
                  </div>
                </Fade>
              )
            })}
            { display && display.edges < 1 &&
              <p>No results matching search criteria. Please try again.</p>
            }
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default JobSearchPage

export const jobQuery = graphql`
  query {
    site {
      buildTime
    }
    properties: allWpJob(sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          title
          slug
          excerpt
          content
          locations {
            nodes {
              id
              slug
            }
          }
          jobTitles {
            nodes {
              id
              slug
            }
          }
          jobTypes {
            nodes {
              id
              slug
            }
          }
        }
      }
    }
    wpPage(databaseId: {eq: 478}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      acf {
        components {
          ... on WpPage_Acf_Components_BasicPage {
            fieldGroupName
            title
            subTitle
          }
          ... on WpPage_Acf_Components_TabsMenu {
            fieldGroupName
            items {
              text
              link
              active
            }
          }
        }
      }
    }
  }
`
