import React, { Component } from 'react'

import Link from '../../utils/link'

class JobCard extends Component {

  render() {

    let { title, slug, excerpt } = this.props

    return (

      <div className='job-card'>

        <div className='job-card__inner'>

          <h3 dangerouslySetInnerHTML={{ __html: title }} />

          <div className='job-card__content' dangerouslySetInnerHTML={{ __html: excerpt }} />

          <div className='job-card__button'>
            <Link to={`/job/${slug}`} >View Job</Link>
          </div>

        </div>
        
      </div>
    )
  }
}

export default JobCard
