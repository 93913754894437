import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Select, { components }  from 'react-select'
import DownArrow from '../assets/images/down-arrow.svg'
import Fade from 'react-reveal/Fade'

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={ DownArrow } className='dropdown-arrow' />
      </components.DropdownIndicator>
    )
  );
};

class SearchForm extends Component {

  state = {
    title: null,
    location: null,
    job_type: null
  };

  updateList = () => {

      let { title, location, job_type } = this.state

      const { list, set } = this.props

      let results = {}

      if(list.edges) {

        results.edges = list.edges.filter((el, i) => {

          const { jobTitles, locations, jobTypes } = el.node

          if( title && ( jobTitles.nodes.filter( item => item.id === title.value) ).length <= 0 ) {
            return false;
          }

          if( location && ( locations.nodes.filter( item => item.id === location.value) ).length <= 0 ) {
            return false;
          }

          if( job_type && ( jobTypes.nodes.filter( item => item.id === job_type.value) ).length <= 0 ) {
            return false;
          }

          return true;

        });
      }

      set(results);
  }

  handleChange = (option, field) => {

    let state = {}
    switch (field && field.action) {
      case 'select-option':
        state[field.name] = option
        this.setState(state, this.updateList)
        break
      default:
        let { name, value } = option.currentTarget
        state[name] = value
        this.setState(state, this.updateList)
        break
    }
  }

  render() {

    let { title, location, job_type } = this.state

    let { titles, locations, job_types } = this.props.data

    const title_list = titles.edges.map((item, index) => ({ label: item.node.name, value: item.node.id }));

    const location_list = locations.edges.map((item, index) => ({ label: item.node.name, value: item.node.id }));

    const job_type_list = job_types.edges.map((item, index) => ({ label: item.node.name, value: item.node.id }));

    return (

      <section className='search'>
        <div className='search__inner'>
          <div className='search__items'>
            <div className='react-form__select react-form__select--title'>

                <label htmlFor="title">

                  Select title

                <Select
                  options={title_list}
                  onChange={this.handleChange}
                  id="title"
                  name="title"
                  value={title}
                  placeholder="Select title"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  components={{ DropdownIndicator }}
                />

                </label>

              </div>

              <div className='react-form__select react-form__select--location'>

                <label htmlFor="location">

                  Select location

                  <Select
                    options={location_list}
                    onChange={this.handleChange}
                    id="location"
                    name="location"
                    value={location}
                    placeholder="Select location"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    components={{ DropdownIndicator }}
                  />

                </label>

              </div>

              <div className='react-form__select react-form__select--job_type'>

                <label htmlFor="location">

                  Select job type

                  <Select
                    options={job_type_list}
                    onChange={this.handleChange}
                    id="job_type"
                    name="job_type"
                    value={job_type}
                    placeholder="Select job type"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    components={{ DropdownIndicator }}
                  />

                </label>

              </div>

          </div>
        </div>
      </section>
  
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        titles:allWpJobTitle(filter: {count: {gt: 0}}) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        locations: allWpLocation(filter: {count: {gt: 0}}) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        job_types: allWpJobType(filter: {count: {gt: 0}}) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    `}
    render={data => <SearchForm data={data} {...props} />}
  />
)
